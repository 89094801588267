import React from "react";
import { useWebEngageMutation } from "../../store/ApiMutaion";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import line from "../../assets/svg/reportspage/Line24.svg";
import ProgressBar from "../../pages/components/ProgressBar";
import certific from "../../assets/svg/reportspage/certificateicon.svg";
import vector from "../../assets/svg/reportspage/Vector.svg";
import best from "../../assets/svg/reportspage/bestSub.svg";
import imporve from "../../assets/svg/reportspage/improveSub.svg";
import CircleProgressBar from "../../pages/components/circleProgressBar";
import leftArrow from "../../assets/svg/reportspage/LeftArrow.svg";
import back from "../../assets/svg/reportspage/leftarow.svg";
import { ROUTES } from "../../constants/routes";
import { CONFIG_VARS } from "../../constants/configuration";

const BasicReport = ({ reportData, access_token, progressordata, examDetails, formetDate, examdetailes }) => {
  const [webEngage] = useWebEngageMutation();
  const history = useHistory();

  const handleBackButtonClick = () => {
    history.push(ROUTES.DASHBOARD);
  };
  const CertificateWebEngage = async () => {
    try {
      await webEngage({
        crn_id: `${examdetailes?.crn_id}`,
        event_name: "Download_Certificate_Inmobius",
        event_data: [
          { attribute: "test_name", value: `${examdetailes?.StudentExamType + " " + examdetailes?.level}` },
          { attribute: "test_type", value: `${examdetailes?.title}` },
          { attribute: "event_id", value: `${examdetailes?.event_id}` },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "page_url", value: "" }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };
  const ViewSollutionWebEngage = async () => {
    try {
      await webEngage({
        crn_id: `${examdetailes?.crn_id}`,
        event_name: "View_Solution_Inmobius",
        event_data: [
          { attribute: "test_name", value: `${examdetailes?.title + " " + examdetailes?.level}` },
          { attribute: "test_type", value: `${examdetailes?.title}` },
          { attribute: "event_id", value: `${examdetailes?.event_id}` },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "page_url", value: "" }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };
  const handleDownloadCertificet = () => {
    const url = `${CONFIG_VARS.certficateUrl}/${examdetailes.crn_id}/${examdetailes.event_id}.pdf`;
    CertificateWebEngage();
    window.open(url);
  };
  const handleViewSolutions = async () => {
    await ViewSollutionWebEngage();
    const baseUrl = `${CONFIG_VARS.scoreViewreportUrl}/test/viewsolutionspage?test_type=e34d1d14-6c33-48fd-900e-e8d3cb76da64`;
    const examId = examdetailes.exam_id;
    const qu_id = examdetailes.qu_id;
    const token = access_token.replace("Bearer ", "");
    const url = `${baseUrl}&question_paper_id=${qu_id}&token=${token}&exam_id=${examId}`;
    // window.open(url);
    window.open(url, "_self", "noreferrer");
  };
  const isPerfomence = (currentExam) => {
    const disallowedSubjects = ["Hots Olympiad", "Math Olympiad"];
    return !disallowedSubjects.includes(currentExam);
  };
  return (
    <div>
      <div className="Rep_btn-cont">
        <button onClick={handleBackButtonClick} className="Rep_back-btn">
          <span>
            <img src={back} />
          </span>
          back
        </button>
        <div className="Rep_back-btn2">
          <div className="Rep_back-btn2In">
            {" "}
            <img onClick={handleBackButtonClick} src={leftArrow} /> <p>Report</p>
          </div>
        </div>
        <button onClick={handleViewSolutions} className="attempt-btn Rep_attempt-btn1">
          View Solutions
        </button>
      </div>
      <div>
        <h1 className="Rep_exam-title">
          {examdetailes?.title} {examdetailes?.level} | {formetDate}
        </h1>
        <div className="cetrificate-cont">
          <div className="Rep-text-cont">
            <img src={certific} alt="note" className="mock_img mock_imgweb" />
            <div className="Rep_mock-Cont">
              <h2 className="mock-text Rep_mock-text">Test Completed</h2>
              <p className="mock-para Rep_mock-para ">Celebrate your achievement! Download your certificate now.</p>
            </div>
            <img src={certific} alt="note" className="mock_img mock_imgmob" />
          </div>
          <button onClick={handleDownloadCertificet} className="attempt-btn Rep_attempt-btn2">
            Download Certificate
          </button>
        </div>

        <h3 className="Rep_sub-head">Overview</h3>
        <div className="Rep_card-cont">
          <div className="Rep_score_card">
            <img src={vector} alt="vector" className="rep_card_vector" />
            <div>
              <h4 className="Rep_score_cardHead">Score</h4>
              <p>
                {reportData?.marks_obtained}
                <span>/{reportData?.maximum_marks} marks</span>
              </p>
            </div>
            <img className="Rep_score_cardLine" src={line} />
            <div className="Rep_score_cardButtom">
              <div>
                <h4 className="Rep_score_cardHead">Accuracy</h4>
                <p className="Rep_score_cardDes">{reportData?.accuracy}%</p>
              </div>
              <div>
                <h4 className="Rep_score_cardHead">Time Taken</h4>
                <p>{reportData?.time_spent_minutes}</p>
              </div>
              <div>
                <h4 className="Rep_score_cardHead">Total Time</h4>
                <p>{examDetails?.duration_in_minutes} M</p>
              </div>
            </div>
          </div>
          <CircleProgressBar
            totalMarks={reportData?.maximum_marks}
            correctMarks={reportData?.correct_answers}
            incorrectMarks={reportData?.incorrect_answers}
            unattempted={reportData?.unattempted_questions}
          />{" "}
        </div>
        {isPerfomence(examdetailes?.title) && (
          <>
            <h3 className="Rep_sub-head">Subject Performance</h3>
            <div className="perform_box">
              <div>
                <div className="perform_webflex">
                  <div>
                    <figure className="mb-0">
                      <img src={best} alt="subone" />
                    </figure>
                  </div>
                  <div className="perform_mobflex">
                    <h3 className="SCRPT-icnhdng no_mb">Best subject</h3>
                    <h4 className="SCRPT-cunt SCRPT-elips">{reportData?.subject_performance?.highest?.section_name}</h4>
                  </div>
                </div>
              </div>
              <div>
                <div className="perform_webflex">
                  <div>
                    <figure className="mb-0">
                      <img src={imporve} alt="subtwo" />
                    </figure>
                  </div>
                  <div className="perform_mobflex">
                    <h3 className="SCRPT-icnhdng no_mb">Scope of improvement</h3>
                    <h4 className="SCRPT-cunt SCRPT-elips">{reportData?.subject_performance?.lowest?.section_name}</h4>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="Rep_Sub_cont">
          <h3 className="Rep_graph_cardHead">Sectionwise Score</h3>
          <div className="Rep_Sub_contIn">
            {Object.keys(progressordata).map((subject) => (
              <div key={subject} className="Rep_Sub_cont-card">
                <h3 className="Rep_Sub_contSubject">{subject}</h3>
                <div className="Rep_progressor-cont">
                  <ProgressBar
                    progress={progressordata[subject]?.marks_obtained}
                    total={progressordata[subject]?.total_marks}
                  />
                  <p>
                    {progressordata[subject]?.marks_obtained}/{progressordata[subject]?.total_marks}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <h3 className="Rep_sub-head">Section-wise breakdown</h3>
    </div>
  );
};

export default BasicReport;
