import React, { useState, useEffect, useCallback } from "react";

import { useStudentLoginAdmissionIDMutation } from "../../store/auth/auth.api";

import { ROUTES } from "../../constants/routes";

import { useForm } from "react-hook-form";

import { JoinButton } from "../components/JoinButton";

import { loginFormResolver } from "../../constants/resolvers";

import hideIcon from "../../assets/images/frontside/hide.png";

import showIcon from "../../assets/images/frontside/eye-show.png";

import { studentLoginIdPasswordFormFields } from "../../constants/constants";

let { loginId, password } = studentLoginIdPasswordFormFields;

import { useHistory } from "react-router-dom/";

import { useWebEngageMutation } from "../../store/ApiMutaion";

export default function DeepLinkLogin({
  toggleTAndC,

  togglePAndP,

  loginIdFromParent,

  passwordFromParent,

  handleToggleLogins
}) {
  const [onStudentLoginIDPassword] = useStudentLoginAdmissionIDMutation();

  const form = useForm({ resolver: loginFormResolver });

  const {
    handleSubmit,

    setValue,

    setError,

    clearErrors,

    register,

    formState: { errors }
  } = form;

  const [showPass, setShowPass] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isPreFilled, setIsPreFilled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const history = useHistory();

  const [webEngage] = useWebEngageMutation();

  const handleCreateAcc = () => {
    history.push(ROUTES.STUDENT_CREATE_ACCOUNT);
  };

  const showPassToggle = () => setShowPass((prev) => !prev);

  const handleInputKeyPress = (fieldName, value) => {
    value = value.replace(/\s/g, "");
    setIsPreFilled(false);
    if (fieldName === "admission_id") {
      setValue(fieldName, value.toUpperCase());

      if (value.length < 6) {
        loginId = false;

        setError("admission_id", { message: "Login ID must be at least 6 characters" });
      } else {
        loginId = true;

        clearErrors("admission_id");
      }
    } else if (fieldName === "password") {
      setValue(fieldName, value);

      if (value.length < 6) {
        password = false;

        setError("password", { message: "Password must be at least 6 characters" });
      } else {
        password = true;

        clearErrors("password");
      }
    }

    const isAllFieldsFilled = loginId && password;

    setIsButtonDisabled(!isAllFieldsFilled);
  };

  const onSubmit = useCallback(
    async ({ admission_id, password }) => {
      if (loading) return;
      setLoading(true);
      setIsButtonDisabled(true);
      const uppercasedAdmissionId = admission_id.toUpperCase();
      const uppercasedPassword = password.toUpperCase();
      try {
        const res = await onStudentLoginIDPassword({
          admission_id: uppercasedAdmissionId,
          password: uppercasedPassword
        });

        if (res?.data?.detail?.includes("User")) {
          setError("admission_id", { message: "Login ID is incorrect or doesn’t exist" });
          setIsButtonDisabled(true);
        } else if (res?.data?.detail?.includes("Incorrect Password")) {
          setError("password", { message: "Login ID & password don’t match" });
          setIsButtonDisabled(true);
        } else if (res?.data?.code !== 200 || res?.error) {
          setError("password", { message: "Oops! Something went wrong. Please try again" });
          setIsButtonDisabled(false);
        }
        if (res?.data?.code === 200) {
          try {
            await webEngage({
              crn_id: `${res.data.details.user.crn_id}`,
              event_name: "Sign_In_Inmobius",
              event_data: [
                { attribute: "Student Login ID", value: `${res.data.details.user.admission_id}` },
                { attribute: "Phone", value: `${res.data.details.user.phone}` },
                { attribute: "Platform", value: "InMobius" },
                { attribute: "page_url", value: "/login" }
              ]
            });
          } catch (error) {
            console.error("Error pushing WebEngage event:", error);
          }
        }
      } catch (error) {
        console.error("Error during login submission:", error);
        setError("password", { message: "Oops! Something went wrong. Please try again" });
      } finally {
        setLoading(false);
      }
    },
    [loading, onStudentLoginIDPassword, webEngage, setError]
  );

  useEffect(() => {
    if (!hasSubmitted && loginIdFromParent && passwordFromParent) {
      setValue("admission_id", loginIdFromParent);
      setValue("password", passwordFromParent);
      onSubmit({ admission_id: loginIdFromParent, password: passwordFromParent });
      setHasSubmitted(true); // Prevent further calls
    }
    setIsPreFilled(loginIdFromParent && passwordFromParent);
  }, [loginIdFromParent, passwordFromParent, setValue, onSubmit, hasSubmitted]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 position-relative mb-12">
          <label className="tab-lable">Login ID</label>
          <input
            type={"text"}
            className={`member--inputbox pe-3 ${errors.admission_id ? "error--strike" : "input-highlight"}`}
            placeholder="Enter your login ID"
            {...register("admission_id", { required: true, minLength: 6 })}
            onChange={(e) => handleInputKeyPress("admission_id", e.target.value, e)}
            required
          />
          {errors.admission_id && !isPreFilled && (
            <span className="error--strike--text">{errors.admission_id.message}</span>
          )}
        </div>
        <div className="col-12 position-relative mb-12">
          <label className="tab-lable">Password</label>
          <div className="input-group">
            <input
              type={showPass ? "text" : "password"}
              className={`member--inputbox pe-3 ${errors.password ? "error--strike" : "input-highlight"}`}
              placeholder="Enter your Password"
              {...register("password", { required: true, minLength: 6 })}
              onChange={(e) => handleInputKeyPress("password", e.target.value, e)}
              required
            />
            <div className="input-group-append">
              <span className="showhide--pic" onClick={showPassToggle}>
                <img src={!showPass ? hideIcon : showIcon} id="eyeicon" alt="" />
              </span>
            </div>
          </div>
          {errors.password && !isPreFilled && <span className="error--strike--text">{errors.password.message}</span>}
          <div className="t-c_class">
            By logging in, you agree with our{" "}
            <span onClick={toggleTAndC} className="gard--textflow mx-1">
              T&C
            </span>{" "}
            and{" "}
            <span className="gard--textflow mx-1" onClick={togglePAndP}>
              PP
            </span>
            .
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <JoinButton buttonName={loading ? "Logging in..." : "Join Now"} isDisabled={isButtonDisabled} />
        </div>
      </div>
      <div className="hr-box">
        <hr className="hr-line" />
        <span>Or</span>
        <hr className="hr-line" />
      </div>
      <div className="col-12 mt-3 mb-3 pb-2 text-center">
        <a className="gard--textflow gard--textflow-mob" style={{ cursor: "pointer" }} onClick={handleToggleLogins}>
          Log in with OTP
        </a>
      </div>
      <div className="col-12 create-ac-link">
        <p className="login--supporttext">
          Don&apos;t have an Inmöbius account?{" "}
          <span className="gard--textflow create-acoount" onClick={handleCreateAcc}>
            create account
          </span>
        </p>
      </div>
    </form>
  );
}
