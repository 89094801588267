import { UAParser } from "ua-parser-js";
import { CONFIG_VARS } from "../constants/configuration";

const getDeviceDetails = () => {
  const parser = new UAParser();
  const result = parser.getResult();

  const manufacturer = result.device.vendor || "Unknown";
  const model = result.device.model || "Unknown";
  const brand = result.device.vendor || "Unknown";
  const os_name = `${result.os.name} ${result.os.version}`;
  const device_category = result.device.type || "web";

  return {
    manufacturer: manufacturer,
    model: model,
    brand: brand,
    os_name: os_name,
    device_category: device_category
  };
};

const getDeviceAndLocationPayload = async () => {
  const payload = {
    city: "N/A",
    state: "N/A",
    device_attributes: []
  };

  try {
    // Step 1: Determine device type
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const isTablet = /Tablet|iPad/i.test(navigator.userAgent);
    const isDesktop = !isMobile && !isTablet;

    // Step 2: Fetch location details (city and state)
    try {
      const ipResponse = await fetch("https://ipapi.co/json/");
      if (ipResponse.ok) {
        const locationData = await ipResponse.json();
        payload.city = locationData.city || "N/A";
        payload.state = locationData.region || "N/A";
      } else {
        console.warn("Failed to fetch location details, response not OK.");
      }
    } catch (error) {
      console.warn("Error fetching location details:", error);
    }

    // Step 3: Fetch user agent details
    let userAgentData = null;
    try {
      userAgentData = await navigator.userAgentData.getHighEntropyValues([
        "architecture",
        "model",
        "platform",
        "platformVersion",
        "fullVersionList"
      ]);
    } catch (error) {
      console.warn("Error fetching user agent data:", error);
    }

    // Device-specific attributes
    const platformDetails = `${userAgentData?.platform || "Unknown"} ${userAgentData?.platformVersion || "Unknown"}`;
    const deviceType = isMobile ? "mobile" : isTablet ? "tablet" : isDesktop ? "desktop" : "Web";

    // Step 4: Fetch brand details if on mobile/tablet
    let marketingName = "N/A";
    let retailBranding = "N/A";

    if (userAgentData?.model) {
      console.log(userAgentData?.model);
      try {
        const brandResponse = await fetch(
          `${CONFIG_VARS.LsqUrl}/v1/lsq/device-attributes?model=${userAgentData?.model}`,
          { headers: { Accept: "*/*" } }
        );
        if (brandResponse.ok) {
          const brandData = await brandResponse.json();
          marketingName = brandData?.MarketingName || "N/A";
          retailBranding = brandData?.RetailBranding || "N/A";
          console.log("Brand details fetched successfully:", marketingName, retailBranding);
        } else {
          console.warn("Brand details fetch failed, response not OK.");
        }
      } catch (error) {
        console.warn("Error fetching brand details:", error);
      }
    } else {
      console.log("No device model found; skipping brand details.");
    }

    // Step 5: Construct device attributes
    payload.device_attributes = [
      { name: "mx_CustomObject_1", value: retailBranding },
      { name: "mx_CustomObject_2", value: userAgentData?.model || "N/A" },
      { name: "mx_CustomObject_3", value: marketingName },
      { name: "mx_CustomObject_4", value: platformDetails },
      { name: "mx_CustomObject_5", value: deviceType }
    ];
  } catch (error) {
    console.error("Critical error while generating payload:", error);

    // Fallback for critical error
    payload.device_attributes = [
      { name: "mx_CustomObject_1", value: "Error retrieving details" },
      { name: "mx_CustomObject_2", value: "N/A" },
      { name: "mx_CustomObject_3", value: "N/A" },
      { name: "mx_CustomObject_4", value: "Error" },
      { name: "mx_CustomObject_5", value: "unknown" }
    ];
  }

  return payload;
};

const createDeviceDetailsPayloadLSQ = () => {
  const deviceDetails = getDeviceDetails();

  const payload = {
    device_attributes: [
      {
        name: "mx_CustomObject_1",
        value: deviceDetails.manufacturer
      },
      {
        name: "mx_CustomObject_2",
        value: deviceDetails.model
      },
      {
        name: "mx_CustomObject_3",
        value: deviceDetails.brand
      },
      {
        name: "mx_CustomObject_4",
        value: deviceDetails.os_name
      },
      {
        name: "mx_CustomObject_5",
        value: deviceDetails.device_category
      }
    ]
  };

  return payload;
};

export { createDeviceDetailsPayloadLSQ, getDeviceDetails, getDeviceAndLocationPayload };
