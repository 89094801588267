import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getIsAuthenticated, getUserInfo } from "../store/auth/selectors";
import { useSelector } from "react-redux";
import DeepLinkLogin from "./DeeplinkComponets/DeepLinkLogin";
import { DeepLinkOtp } from "./DeeplinkComponets/DeepLinkOtp";
import { useEventsMutation } from "../store/ApiMutaion";
import { useDispatch } from "react-redux";
import { setSelectedEventsDetails } from "../store/auth/auth.slice";
import { setExamStatus } from "../store/app/app.slice";
// Assets
import mobileDash from "../assets/images/Deeplink/mobile/mobileDash.png";
import webDash from "../assets/images/Deeplink/web/webDash.png";
import mobileReports from "../assets/images/Deeplink/mobile/mobileReports.png";
import webReports from "../assets/images/Deeplink/web/webReports.png";
import mobileHots from "../assets/images/Deeplink/mobile/mobileHots.png";
import webHots from "../assets/images/Deeplink/web/webHots.png";
import mobileScore from "../assets/images/Deeplink/mobile/mobileScore.png";
import webScore from "../assets/images/Deeplink/web/webScore.png";
import mobileMath from "../assets/images/Deeplink/mobile/mobileMath.png";
import webMath from "../assets/images/Deeplink/web/webMath.png";
import mobileIeamsat from "../assets/images/Deeplink/mobile/mobileIeamsat.png";
import webIeamsat from "../assets/images/Deeplink/web/webIeamsat.png";

const DeepLink = ({ toggleTAndC, togglePAndP }) => {
  const { path } = useParams();
  const location = useLocation();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [login, setLogin] = useState(false);
  const [otpLogin, setOtpLogin] = useState(false);
  const [fetchEvents] = useEventsMutation();
  const user = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  const [hasFetchedEvent, setHasFetchedEvent] = useState(false); // Track event fetch status

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const password = queryParams.get("password");
  const exam_status = queryParams.get("exam_status");
  const exam = queryParams.get("exam");
  const crnIdFromUrl = queryParams.get("crnId");
  const eventIdFromUrl = queryParams.get("eventId");
  const bgMedia = () => {
    // Automatically detect device type
    const deviceType = window.innerWidth <= 768 ? "mobile" : "web";

    switch (path) {
      case "dashboard":
        return deviceType === "mobile" ? `url(${mobileDash})` : `url(${webDash})`;
      case "reports":
        return deviceType === "mobile" ? `url(${mobileReports})` : `url(${webReports})`;
      case "exampage":
        switch (exam) {
          case "hots":
            return deviceType === "mobile" ? `url(${mobileHots})` : `url(${webHots})`;
          case "score":
            return deviceType === "mobile" ? `url(${mobileScore})` : `url(${webScore})`;
          case "math":
            return deviceType === "mobile" ? `url(${mobileMath})` : `url(${webMath})`;
          case "ieamsat":
            return deviceType === "mobile" ? `url(${mobileIeamsat})` : `url(${webIeamsat})`;
          default:
            return deviceType === "mobile" ? `url(${mobileDash})` : `url(${webDash})`;
        }
      default:
        return deviceType === "mobile" ? `url(${mobileDash})` : `url(${webDash})`;
    }
  };

  const fetchEvent = async (crnIdFromUrl, eventIdFromUrl, exam_status) => {
    if (hasFetchedEvent) return; // Prevent fetching if already done
    let mappedEvents;
    console.log(exam_status);
    try {
      const res = await fetchEvents();

      if (!res?.data?.error) {
        let eventArray;

        // Determine the event array based on the 'exam_status' parameter
        if (exam_status === "missed") {
          eventArray = res?.data?.past_events?.unattempted_events;
        } else if (exam_status === "completed") {
          console.log(exam_status);
          eventArray = res?.data?.past_events?.attempted_events;
        } else if (exam_status === "scheduled") {
          eventArray = res?.data?.scheduled_events;
        } else {
          console.error("Invalid exam_status type");
          return;
        }

        if (eventArray?.length > 0) {
          const filteredEvents = eventArray.filter((event) => {
            return event?.crn_id === crnIdFromUrl && event?.event_id === eventIdFromUrl;
          });
          if (filteredEvents.length > 0) {
            console.log(filteredEvents);
            mappedEvents = filteredEvents.map((value) => ({
              title: value?.event_details?.name,
              url: value?.event_details?.provider_metadata?.event_link,
              startRecur: value?.event_details?.start_date_time,
              endRecur: value?.event_details?.end_date_time,
              exam_id: value?.event_details?.exam_id,
              exam_attempted: value?.exam_attempted,
              crn_id: value?.crn_id,
              event_id: value?.event_id,
              event_code: value?.event_details?.event_code,
              grade_id: value?.student_details?.grade_id,
              level: value?.event_details?.level,
              examType: value?.event_details?.exam_name,
              StudentExamType: user.exam,
              qu_id: value?.event_details?.question_paper_id,
              round: value?.event_details?.round,
              submitedAt: value?.exam_attempted_at,
              is_paid_report: value?.event_details?.is_paid_report
            }));
            console.log(mappedEvents);
            dispatch(setSelectedEventsDetails(mappedEvents[0]));
            setHasFetchedEvent(true);
          } else {
            history.push(`/dashboard`);
          }
        }
      } else if (res?.data?.error || res?.statusCode !== 200) {
        console.error("Error:", res.error);
      }
    } catch (err) {
      console.error("Fetch Error:", err);
    }
    return mappedEvents;
  };

  const landOnExampage = async (crnIdFromUrl, eventIdFromUrl, exam_status) => {
    if (crnIdFromUrl && eventIdFromUrl && !hasFetchedEvent) {
      await fetchEvent(crnIdFromUrl, eventIdFromUrl, exam_status);
      history.push(`/exam-page`);
    }
  };
  const landOnReportspage = async (crnIdFromUrl, eventIdFromUrl, exam_status) => {
    if (crnIdFromUrl && eventIdFromUrl && !hasFetchedEvent) {
      const res = await fetchEvent(crnIdFromUrl, eventIdFromUrl, exam_status);
      history.push(`/reports/${crnIdFromUrl}/${res[0]?.exam_id}`);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setLogin(true);
    } else {
      if (path === "dashboard") {
        dispatch(setExamStatus(exam_status));
        history.push(`/dashboard`);
      } else if (path === "exampage") {
        landOnExampage(crnIdFromUrl, eventIdFromUrl, exam_status);
      } else if (path === "reports") {
        const exam_status = "completed";
        landOnReportspage(crnIdFromUrl, eventIdFromUrl, exam_status);
      }
    }
  }, [isAuthenticated, exam_status]);

  const handleToggleLogins = () => {
    setOtpLogin(!otpLogin);
  };
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div
        style={{
          backgroundImage: bgMedia(),
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {login && (
          <div
            className="membership__card2"
            style={{
              backgroundColor: "radial-gradient(96.91% 96.91% at 96.41% 2.3%, rgb(15 63 213) 0%, rgb(3 14 51) 100%);"
            }}
          >
            <h3 className="tab-heading">Student Login</h3>
            {!otpLogin ? (
              <DeepLinkLogin
                toggleTAndC={toggleTAndC}
                togglePAndP={togglePAndP}
                loginIdFromParent={id}
                passwordFromParent={password}
                handleToggleLogins={handleToggleLogins}
              />
            ) : (
              <DeepLinkOtp
                toggleTAndC={toggleTAndC}
                togglePAndP={togglePAndP}
                handleToggleLogins={handleToggleLogins}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeepLink;
