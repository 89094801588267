import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG_VARS } from "../../constants/configuration";
import { phoneNumberParserUtils } from "../../utils/phoneNumber-parser-utils";

const { otpBaseUrl } = CONFIG_VARS;

export const otpApi = createApi({
  reducerPath: "otpApi",
  baseQuery: fetchBaseQuery({
    baseUrl: otpBaseUrl
  }),
  endpoints: (build) => ({
    sendOTP: build.mutation({
      query: (data) => ({
        url: `/generate-otp`,
        method: "POST",
        body: {
          ...phoneNumberParserUtils(data.phoneNumber),
          tenant_id: 99999,
          product_id: 100
        }
      })
    }),
    verifyOTP: build.mutation({
      query: (data) => ({
        url: `/validate-otp`,
        method: "POST",
        body: {
          ...phoneNumberParserUtils(data.phoneNumber),
          otp: data.otpCode,
          tenant_id: 99999,
          product_id: 100
        }
      })
    })
  })
});

export const { useSendOTPMutation, useVerifyOTPMutation } = otpApi;
