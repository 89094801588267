import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabID: 1,
  modalState: {
    open: false,
    content: null,
    title: ""
  },
  failedPayment: { isLoader: false, isfailed: false, res: null },
  exam_Status: "",
  landed_url: ""
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setActiveTab(state, { payload }) {
      state.tabID = payload;
    },
    setModalState(state, { payload }) {
      state.modalState = {
        ...initialState.modalState,
        ...payload,
        open: true
      };
    },
    closeModal(state) {
      state.modalState = initialState.modalState;
    },
    setFailedPayment: (state, action) => {
      const { isLoader, isFailed, res } = action.payload;
      state.failedPayment = { isLoader, isFailed, res };
    },
    setExamStatus(state, action) {
      state.exam_Status = action.payload;
    },
    setLandedUrl(state, action) {
      state.landed_url = action.payload;
    }
  },
  extraReducers: () => {}
});

export const { setActiveTab, setModalState, closeModal, setFailedPayment, setExamStatus, setLandedUrl } =
  appSlice.actions;
export default appSlice.reducer;
