import React, { useEffect, useState } from "react";
import g64 from "../assets/svg/exampage/g64.svg";
import note from "../assets/svg/exampage/note.svg";
import planet from "../assets/svg/exampage/planet.svg";
import other from "../assets/svg/exampage/other1.svg";
import certific from "../assets/svg/reportspage/certificateicon.svg";
import { useSelector } from "react-redux";
import { getSelectedEventsDetails, getToken } from "../store/auth/selectors";
import { useExamStatusMutation, useMockTestMutation } from "../store/ApiMutaion";
import { ExamPageBanner } from "./components/examPageBanner";
import { ExamPageAtempt } from "./components/ExamPageAtempt";
const ExamPage = () => {
  const [fetchExamStatus] = useExamStatusMutation();
  const [fetchMockTest] = useMockTestMutation();
  const [status, setStatus] = useState();
  const examdetailes = useSelector(getSelectedEventsDetails);
  console.log(examdetailes);
  const currentExam = examdetailes.title.toLowerCase();
  const access_token = useSelector(getToken);
  const mock = false;
  const handleFetchExamStatus = async (examId) => {
    try {
      const data = await fetchExamStatus(examId);
      setStatus(data.data.status);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleStartMockTest = async () => {
    const body = {
      event_code: examdetailes.event_code,
      level: examdetailes.level,
      exam: examdetailes.StudentExamType,
      grade_id: examdetailes.grade_id
    };
    try {
      const data = await fetchMockTest(body);
      const baseUrl = examdetailes.url;
      const examId = data?.data?.exam_details?.exam_id;
      const token = access_token.replace("Bearer ", "");
      const url = `${baseUrl}&exam_id=${examId}&token=${token}`;
      window.open(url, "_self", "noreferrer");
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handleFetchExamStatus(examdetailes.exam_id);
  }, [examdetailes]);
  const isExam = "exam";

  return (
    <div>
      <ExamPageBanner currentExam={currentExam} />

      {isExam === "exam" ? (
        <ExamPageAtempt status={status} />
      ) : isExam === "completed" ? (
        <div className="complete-test-card">
          <h3 className=" complete-heading">Compleated Test</h3>
          <div className="reports-cont">
            <div className="report-cards">
              <p className="report-time">10th jul, 2021 7:30 pm - 8:30 pm</p>
              <h2 className="report-score">Score: 500/700</h2>
              <button className="view-cta">View Report</button>
            </div>
            <div className="report-cards">
              <p className="report-time">10th jul, 2021 7:30 pm - 8:30 pm</p>
              <h2 className="report-score">Score: 500/700</h2>
              <button className="view-cta">View Report</button>
            </div>
            <div className="report-cards">
              <p className="report-time">10th jul, 2021 7:30 pm - 8:30 pm</p>
              <h2 className="report-score">Score: 500/700</h2>
              <button className="view-cta">View Report</button>
            </div>
          </div>
          <button className="view-more">view more</button>
        </div>
      ) : (
        ""
      )}
      <div className="complete-test-card">
        <h2 className="reword-head">
          Take the Test and Unlock Exciting <span className="reword-hiligt">Rewards and Scholarships!</span>
        </h2>
        <p className="reword-des">Over 3,397 students won rewards and scholarships.</p>
        <div className="certificate-cont">
          <div className="certificate-card">
            {" "}
            <img src={certific} alt="certificet" />
            <h3 className="certificet-name">Score Certificate</h3>
          </div>
          <div className="certificate-card">
            {" "}
            <img src={g64} alt="certificet" />
            <h3 className="certificet-name">
              1000 CR Worth <br />
              Scholarship
            </h3>
          </div>
          <div className="certificate-card">
            {" "}
            <img src={planet} alt="certificet" />
            <h3 className="certificet-name">NASA Trip</h3>
          </div>
          <div className="certificate-card visibleblock">
            {" "}
            <img src={other} alt="certificet  " />
            <h3 className="certificet-name">
              Many More
              <br />
              Exciting Rewords
            </h3>
          </div>
        </div>
      </div>
      {mock && (
        <div className="mock-section">
          <div className="text-cont">
            <img src={note} alt="note" className="mock_img" />
            <h2 className="mock-text">Boost your preparation with our mock test</h2>
          </div>
          <button onClick={handleStartMockTest} className="mock-btn">
            Take Test
          </button>
        </div>
      )}
    </div>
  );
};
export default ExamPage;
